import React from 'react';
import './App.css';
import CrossWord from 'components/CrossWord';
import { Card } from 'components/ui/card';

function App() {
  return (
    <div className="App">
      <Card className="p-6">
        <CrossWord />
      </Card>
    </div>
  );
}

export default App;
