import ReactCrossword from '@jaredreisinger/react-crossword';
import React from 'react';
import { data } from '../data/crossword';

export default function CrossWord() {
  return (
    <div style={{ width: '25em', display: 'flex' }}>
      <ReactCrossword data={data} />
    </div>
  );
}
